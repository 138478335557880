import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import WidgetTracker from "../common/components/WidgetTracker";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import { ContactDbWidget, Scope } from "../api/contacts/contacts.typings";
import { mapContactDtoToContactOutputDto } from "../common/mappers/contact.output.mappers";
import { ContactOutputDto } from "../common/typings/contacts.typings";
import { ContactSingleSelectPicker } from "./ContactSingleSelectPicker";
import { WithSGB4 } from "../common/components/WithSGB4";
import { SGBSSize } from "@sgbs-ui/core";
import {
  CommonPickerProps,
  ContactDisplayOption,
  CreateLinkMode,
  CreateLinkPickerProps,
  EmitOnChangeMode,
} from "../common/typings";
import { useCreateLinkClicked } from "../common/hooks/useCreateLinkClicked";
import { CONTACT_DISPLAY_OPTION, CREATE_LINK_MODE, EMIT_MODE, PLACEHOLDER, SIZE } from "../common/constants";
import { SgDashboardProps, SgDashboardWidgetProps } from "../common/sgDashboard";
import { getInitialSingleSelection, notifyChangeToSgDashboard, useEmitOnMount } from "../common/hooks/useUserSelection";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

const TAG = "ic-contact-single-picker";

export interface Props
  extends CommonPickerProps<{ contact: ContactOutputDto | null }, string>,
    CreateLinkPickerProps,
    SgDashboardProps {
  selectedId?: string;
  readonlyId?: string;
  internalOnly?: string;
  activeOnly?: string;
  withQualityIndicators?: string;
  useHistory?: string;
  displayOption?: ContactDisplayOption;
  scope?: Scope;
  suggestionIds?: string[];
  contactsIds?: string[];
  onClearSuggestions?: (items: ContactDbWidget[]) => void;
  onRemoveSuggestion?: (item: ContactDbWidget) => void;
}

export const ContactPickerWidget: React.FC<Props> = ({
  selectedId,
  readonlyId,
  errorMessage,
  inError,
  maxResultCount,
  id,
  internalOnly,
  activeOnly,
  withQualityIndicators,
  useHistory,
  onCreateLinkClicked,
  onReady,
  onChange,
  placeholder = PLACEHOLDER,
  createLinkMode = CREATE_LINK_MODE,
  displayOption = CONTACT_DISPLAY_OPTION,
  size = SIZE,
  emitMode = EMIT_MODE,
  outline,
  createActionText,
  disabled,
  scope,
  suggestionIds,
  contactsIds,
  // sg dashboard
  userSelection,
  setUserSelection,
  emitState,
  theme,
  onClearSuggestions,
  onRemoveSuggestion,
}) => {
  const handleOnSelectionChange = (contact: ContactDbWidget | null): void => {
    notifyChangeToSgDashboard({ userSelection, setUserSelection, emitState }, contact);
    const output = contact ? mapContactDtoToContactOutputDto(contact) : null;
    onChange?.({ ...output, contact: output });
  };
  useEmitOnMount(userSelection, emitMode, () => onChange?.({ contact: null }));
  const initSelection = getInitialSingleSelection(userSelection, selectedId);

  const handleOnCreateLinkClicked = useCreateLinkClicked(createLinkMode, "contacts", onCreateLinkClicked);
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <WithSGB4 theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ContactSingleSelectPicker
          id={id}
          placeholder={placeholder}
          maxResultCount={maxResultCount}
          selectedId={initSelection}
          readonlyId={readonlyId}
          displayOption={displayOption}
          createLinkMode={createLinkMode}
          emitMode={emitMode}
          createActionText={createActionText}
          outline={convertToBoolOrUndefined(outline)}
          disabled={convertToBoolOrUndefined(disabled)}
          inError={convertToBoolOrUndefined(inError)}
          errorMessage={errorMessage}
          internalOnly={convertToBoolOrUndefined(internalOnly)}
          scope={scope}
          suggestionIds={suggestionIds}
          activeOnly={convertToBoolOrUndefined(activeOnly)}
          withQualityIndicators={convertToBoolOrUndefined(withQualityIndicators)}
          useHistory={convertToBoolOrUndefined(useHistory)}
          onChange={handleOnSelectionChange}
          onReady={onReady}
          onCreateLinkClicked={handleOnCreateLinkClicked}
          size={size}
          onClearSuggestions={onClearSuggestions}
          onRemoveSuggestion={onRemoveSuggestion}
          contactsIds={contactsIds}
        />
        <WidgetTracker widgetName={TAG} />
      </QueryClientProvider>
    </WithSGB4>
  );
};

widgetize(
  TAG,
  ContactPickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    suggestionIds: WidgetPropsMapping.asObject(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
    createActionText: WidgetPropsMapping.asString(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    selectedId: WidgetPropsMapping.asString(),
    readonlyId: WidgetPropsMapping.asString(),
    internalOnly: WidgetPropsMapping.asString(),
    scope: WidgetPropsMapping.asString() as PropMapping<Props, Scope>,
    activeOnly: WidgetPropsMapping.asString(),
    withQualityIndicators: WidgetPropsMapping.asString(),
    useHistory: WidgetPropsMapping.asString(),
    displayOption: WidgetPropsMapping.asString() as PropMapping<Props, ContactDisplayOption>,
    contactsIds: WidgetPropsMapping.asObject(),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    onCreateLinkClicked: WidgetPropsMapping.asEventEmitter("create-link-clicked"),
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onClearSuggestions: WidgetPropsMapping.asEventEmitter("clear-suggestions"),
    onRemoveSuggestion: WidgetPropsMapping.asEventEmitter("remove-suggestion"),
    ...SgDashboardWidgetProps,
  },
  { neverInjectGlobalCss: true }
);
