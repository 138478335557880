import * as React from "react";
import { ContactDbWidget, Scope } from "../api/contacts/contacts.typings";
import { getContactById, searchContacts, getContactsById } from "../api/contacts/contacts.api";
import { mapToSearchCriteria } from "../api/contacts/contacts.mapper";
import { Select } from "@sgbs-ui/core";
import { renderContact } from "../common/components/renderContact";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../common/sgConnectScopes";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { CommonPickerProps, ContactDisplayOption, CreateLinkPickerProps } from "../common/typings";
import { useSingleSelectPicker } from "../common/hooks/useSingleSelectPicker";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { isEmpty, map } from "lodash-es";
import { useFetchGeneric } from "../common/hooks/useFetchGeneric";
import { useFetchSuggestions } from "../common/hooks/useFetchSuggestions";
import { useFetchHistory } from "../common/hooks/useHistory";
import { useGetContactIndicatorsQuery } from "../common/components/ContactQuality/Queries/ContactQuality.queries";
import { ApiRepository, ApiRequestConfig } from "@ic-anywhere/ic-dal";

const CONTACTS_SEARCH_FIELDS = ["name", "lastName", "fullName", "email", "givenName"];
const PICKER_NAME = "ic-contact-single-picker";

export interface Props extends CommonPickerProps<ContactDbWidget | null>, CreateLinkPickerProps {
  internalOnly?: boolean;
  activeOnly?: boolean;
  withQualityIndicators?: boolean;
  useHistory?: boolean;
  selectedId?: string;
  readonlyId?: string;
  displayOption: ContactDisplayOption;
  scope?: Scope;
  suggestionIds?: string[];
  contactsIds?: string[];
  onClearSuggestions?: (items: ContactDbWidget[]) => void;
  onRemoveSuggestion?: (item: ContactDbWidget) => void;
}

export const ContactSingleSelectPicker: React.FC<Props> = ({
  selectedId,
  readonlyId,
  placeholder,
  internalOnly,
  activeOnly,
  withQualityIndicators = false,
  useHistory,
  maxResultCount,
  emitMode,
  scope,
  suggestionIds,
  contactsIds,
  displayOption,
  onReady,
  onChange,
  onClearSuggestions,
  onRemoveSuggestion,
  ...props
}: Props) => {
  const pickerId = useGenerateId(props.id);
  const fetch = useSgConnectFetch(SGMContactScope)?.fetch as FetchFn;

  const fetchById = React.useCallback(
    (id: string): Promise<ContactDbWidget | null> => {
      return getContactById(fetch, id).catch(() => null);
    },
    [fetch]
  );

  const fetchContacts = React.useCallback(
    (term: string, repo: ApiRepository, signal: AbortSignal): Promise<ContactDbWidget[]> => {
      return searchContacts(
        repo,
        mapToSearchCriteria({ term, onlyActive: activeOnly, isInternal: internalOnly, maxResultCount, scope }),
        { signal } as ApiRequestConfig
      );
    },
    [fetch, activeOnly, maxResultCount, internalOnly, scope]
  );

  const fetchContactsByIds = React.useCallback(
    (contactsIdsFromHistory?: string[]): Promise<ContactDbWidget[]> => {
      return getContactsById(fetch, contactsIds ?? contactsIdsFromHistory ?? []);
    },
    [fetch, contactsIds]
  );

  const [selectedContact, readOnlyContacts, onSelect] = useSingleSelectPicker(
    fetchById,
    emitMode,
    onChange,
    onReady,
    selectedId,
    readonlyId
  );

  const [contacts, isLoading, hasError, errorMessage, onTermChange, term] = useFetchGeneric<ContactDbWidget>(
    fetchContacts,
    fetchContactsByIds,
    CONTACTS_SEARCH_FIELDS,
    contactsIds
  );

  const { suggestions } = useFetchSuggestions<ContactDbWidget>(
    () =>
      getContactsById(fetch, suggestionIds ?? []).then(suggestions =>
        map(suggestions, suggestion => ({ ...suggestion, isSuggestion: true } as ContactDbWidget))
      ),
    suggestionIds
  );

  const [history, setHistory, removeItemFromHistory, clearHistory] = useFetchHistory(
    fetchContactsByIds,
    PICKER_NAME,
    useHistory
  );

  const contactsToDisplay = isEmpty(contacts) && !term ? [...suggestions, ...history] : contacts;
  const idsToRequestIndicators = selectedContact ? [selectedContact] : [...contactsToDisplay, ...readOnlyContacts];
  const { data: contactQuality } = useGetContactIndicatorsQuery(
    idsToRequestIndicators.map(c => c.id),
    withQualityIndicators && !internalOnly
  );
  return (
    <>
      <Select.AsyncSingleSelect<ContactDbWidget>
        id={pickerId}
        items={contactsToDisplay}
        selectedItem={selectedContact}
        readOnlyItems={readOnlyContacts}
        idField="id"
        isSuggestionField="isSuggestion"
        labelField="fullName"
        onTermChange={onTermChange}
        onChange={item => {
          if (item) {
            setHistory([item?.id]);
          }
          onSelect(item);
        }}
        iconName="person"
        placeholder={placeholder}
        inError={hasError || props.inError || !!errorMessage}
        errorMessage={errorMessage}
        size={props.size}
        isLoading={isLoading}
        keepOrder={false}
        onCreatClick={props.onCreateLinkClicked}
        showCreateAction={props.createLinkMode !== "none"}
        isOutline={props.outline}
        disabled={props.disabled}
        createActionText={props.createActionText}
        dropdownZIndex={1000}
        onClearSuggestions={(items: ContactDbWidget[]) => {
          clearHistory();
          onClearSuggestions?.(items);
        }}
        onRemoveSuggestion={(item: ContactDbWidget) => {
          removeItemFromHistory([item.id]);
          onRemoveSuggestion?.(item);
        }}
        noResultMessage={"No results found. Try another contact."}
      >
        {(item, props) =>
          renderContact(
            displayOption,
            contactQuality?.find(cq => cq.contact.id === item.id)
          )(item, props)
        }
      </Select.AsyncSingleSelect>
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};
